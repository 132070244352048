<template>
  <section id="read-all" class="container">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <!-- <h3 class="text-transparencia mb-3">
      <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i>Volver</a>
    </h3> -->
    <div class="rounded-box bg-card-green">
      <div v-if="datos.tipoContenido == 'SIM'">
        <view-actividad-detalle-simple :datos="datos"></view-actividad-detalle-simple>
      </div>
      <div v-if="datos.tipoContenido == 'IMG'">
        <view-actividad-detalle-imagen :datos="datos"></view-actividad-detalle-imagen>
      </div>
      <div v-if="datos.tipoContenido == 'VID'">
        <view-actividad-detalle-video :datos="datos"></view-actividad-detalle-video>
      </div>
      <div v-if="datos.tipoContenido == 'IVM'">
        <view-actividad-detalle-imagen-video :datos="datos"></view-actividad-detalle-imagen-video>
      </div>
      <div v-if="datos.tipoContenido == 'NOC'">
        <view-actividad-detalle-sincontenido :datos="datos"></view-actividad-detalle-sincontenido>
      </div>
    </div>
  </section>
</template>

<script>
import viewActividadDetalleSimple from "@/components/share/view-actividad-detalle-simple";
import viewActividadDetalleSinContenido from "@/components/share/view-actividad-detalle-sincontenido";
import viewActividadDetalleImagen from "@/components/share/view-actividad-detalle-imagen";
import viewActividadDetalleVideo from "@/components/share/view-actividad-detalle-video";
import viewActividadDetalleImagenVideo from "@/components/share/view-actividad-detalle-imagen-video";
import layoutBreadcrumb from "@/layout/layout-breadcrumb";

export default {
  name: "view-detalle",
  metaInfo: {
    title: "Detalle",
  },
  data: () => ({
    datos: {},
    tipoContenido: null,
  }),
  components: {
    viewActividadDetalleSimple,
    viewActividadDetalleImagen,
    viewActividadDetalleVideo,
    viewActividadDetalleImagenVideo,
    viewActividadDetalleSinContenido,
    layoutBreadcrumb
  },
  methods: {
    fetchDatos: async function(id) {
      var _params = {};
      _params["Id"] = id;
      await this.$http.get(this.apiRoute("Common", "GetNuestroTrabajoId"), { params: _params }).then(function(response) {
        if (!response.body.error) {
          this.datos = response.body.data;
        }
      });
    },
  },
  mounted: async function() {
    var id = this.$route.params.id;

    if (id != null) {
      await this.fetchDatos(id);
    }

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Nuestro trabajo", url: "/nuestrotrabajo", icon: "", active: false },
      { name: "Detalle", url: "", icon: "", active: true  },
    ];
  },
};
</script>
